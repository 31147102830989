<template>
 <nav id="navbarExample" class="navbar navbar-expand-lg fixed-top navbar-light" aria-label="Main navigation">
  <div class="container" style="text-align: center; align-items: center; align-content: center;">
       <a class="navbar-brand logo-text" href="#" style="color: red;">SWISS DIGITAL SOLUTIONS</a> 
      <button class="navbar-toggler p-0 border-0" type="button" id="navbarSideCollapse" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
      </button>
      <div class="navbar-collapse offcanvas-collapse sidebar-container" id="navbarsExampleDefault">
          <ul class="navbar-nav ms-auto navbar-nav-scroll">
              <li class="nav-item">
                  <a class="nav-link active" aria-current="page" href="#header">Home</a>
              </li>
              <li class="nav-item">
                  <a class="nav-link" href="#aboutUs">About Us</a>
              </li>
              <li class="nav-item">
                  <a class="nav-link" href="#services">Services</a>
              </li>
              <li class="nav-item">
                  <a class="nav-link" href="#features">Technology & Innovation</a>
              </li>
              <li class="nav-item dropdown">
                  <a class="nav-link dropdown-toggle" href="#" id="dropdown01" data-bs-toggle="dropdown" aria-expanded="false">Products</a>
                  <ul class="dropdown-menu" aria-labelledby="dropdown01">
                      <li><a class="dropdown-item" href="https://blutec.app/" target="_blank">Blutec App</a></li>
                      <li><div class="dropdown-divider"></div></li>
                      <li><a class="dropdown-item" href="https://routesurvey.app/" target="_blank">Route Survey App</a></li>
                      <li><div class="dropdown-divider"></div></li>
                      <li><a class="dropdown-item" href="https://survys.com/login" target="_blank">Marine Survey App</a></li>
                  </ul>
              </li>
          </ul>
          <span class="nav-item nav-link">
              <a class="btn-solid-sm" href="#contact">Contact Us</a>
          </span>
      </div> 
  </div> 
</nav>
<header id="header" class="header">
  <div class="container">
      <div class="row">
          <div class="col-lg-6 col-xl-5">
              <div class="text-container">
                  <h1 class="h1-large">Pioneering the Future of Digital Experiences</h1>
                  <p class="p-large">Bringing Swiss precision to software, mobile, and virtual reality application development.</p>
                  <a class="btn-solid-lg" href="#services">Offered services</a>
              </div>
          </div> 
          <div class="col-lg-6 col-xl-7">
              <div class="image-container">
                  <img class="img-fluid" src="assets/images/123.png" alt="alternative" />
              </div> 
          </div> 
      </div> 
  </div> 
</header> 
<div id="services" class="cards-1 bg-gray">
  <div class="container" >
      <div class="row"  style="text-align: center;">
          <div class="col-lg-12">
              <h2 style="color: rgb(163, 9, 9);">Explore our services</h2>
          </div> 
      </div>
        <div class="row" style="text-align: center;  padding-left: 10%;">
          <div class="col-lg-12">
              
            
              <div class="card col-lg-6" style="align-items: center;" >
                  <div class="card-icon blue">
                      <span class="fas fa-desktop-alt"></span>
                  </div>
                  <div class="card-body">
                      <h5 class="card-title">Software Application Development</h5>
                      <p>Experience streamlined processes and improved efficiency with our custom software solutions.</p>
                      <a class="read-more no-line" href="#">Learn more <span class="fas fa-long-arrow-alt-right"></span></a>
                  </div>
              </div>
              
              <div class="card col-lg-6" style="align-items: center; ">
                  <div class="card-icon yellow">
                   
                      <span class="fas fa-mobile"></span>
                  </div>
                  <div class="card-body">
                      <h5 class="card-title">Mobile Application Development</h5>
                      <p>Connect with your audience on-the-go with responsive, user-centric mobile applications.</p>
                      <a class="read-more no-line" href="#">Learn more <span class="fas fa-long-arrow-alt-right"></span></a>
                  </div>
              </div>
         </div>
        </div>

        <div class="row"  style="text-align: center; padding-left: 10%;">
          <div class="col-lg-12">
              <div class="card col-6" style="align-items: center; ">
                  <div class="card-icon green">
                      <span class="fas fa-vr-cardboard"></span>
                  </div>
                  <div class="card-body">
                      <h5 class="card-title">Virtual Reality Development</h5>
                      <p>Step into the future with immersive VR applications. From gamifications to realistic simulations, we bring your vision to life.</p>
                      <a class="read-more no-line" href="#">Learn more <span class="fas fa-long-arrow-alt-right"></span></a>
                  </div>
              </div>
              
            
              <div class="card col-6" style="align-items: center; ">
                  <div class="card-icon red">
                      <span class="fas fa-tachograph-digital"></span>
                      
                  </div>
                  <div class="card-body"                                        >
                      <h5 class="card-title">Digital Twin Technology</h5>
                      <p>Reimagine asset management and system modeling with our digital twin technology.</p>
                      <a class="read-more no-line" href="#">Learn more <span class="fas fa-long-arrow-alt-right"></span></a>
                  </div>
              </div>
              
          </div>
      </div>
  </div>
</div> 
<div id="details" class="basic-1">
  <div class="container">
      <div class="row">
          <div class="col-lg-6 col-xl-7">
              <div class="image-container">
                  <img class="img-fluid" src="assets/images/new1.png" alt="alternative" />
              </div> 
          </div> 
          <div class="col-lg-6 col-xl-5">
              <div class="text-container">
                  <div class="section-title">WHAT WE DO</div>
                  <h2>Transforming logistics through innovation and technology</h2>
                  <p style="text-align: justify;"> At Swiss Digital Solution, we redefine the logistics industry by integrating cutting-edge technology, automation, and digital twin solutions. Our expertise delivers optimized supply chain management, real-time tracking, and enhanced customer experiences.</p>
                  <a class="btn-solid-reg" href="#contact">Contact Us</a>
              </div> 
          </div> 
      </div>
  </div> 
</div>
<div id="aboutUs" class="basic-2">
  <div class="container">
      <div class="row">
          <div class="col-lg-6 col-xl-5">
              <div class="text-container">
                  <div class="section-title">ABOUT US</div>
                  <h2>At Swiss Digital Solutions</h2>
                  <p style="text-align: justify;"> we blend Swiss precision with innovative technology. Based in Switzerland and serving the global market, our team is dedicated to transforming digital landscapes.
                  <span style="text-align: justify;"> <br/>Whether it is through software automation, the power of digital twin technology, or groundbreaking virtual reality experiences, we are not just developing; we are pioneering. </span> </p>
                  <a class="btn-outline-reg" href="#">Details</a>
              </div> 
          </div> 
          <div class="col-lg-6 col-xl-7">
              <div class="image-container">
                  <img class="img-fluid" src="assets/images/details-2.png" alt="alternative" />
              </div> 
          </div> 
      </div>
  </div> 
</div> 
<div id="features" class="accordion-1">
  <div class="container">
      <div class="row">
          <div class="col-xl-12">
              <h2 class="h2-heading">Technology & Innovation</h2>
              <p class="p-heading">Our commitment is to the cutting edge. By constantly staying ahead of technological curves, we ensure our clients are equipped with the latest and most effective tools in the market.<br/> Our emphasis on automation, digital twin technology, and immersive VR is a testament to our forward-thinking approach</p>
          </div> 
      </div>   
      <div class="row">
          <div class="col-xl-5">
              <div class="accordion" id="accordionExample">
                  
                 
                  <div class="accordion-item">
                      <div class="accordion-icon">
                          <span class="fas fa-microchip"></span>
                      </div> 
                      <div class="accordion-header" id="headingOne">
                          <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                            Cutting-edge technology
                          </button>
                      </div>
                      <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                      </div>
                  </div>
                 
                 
                  <div class="accordion-item">
                      <div class="accordion-icon blue">
                          <span class="fas fa-robot"></span>
                      </div> 
                      <div class="accordion-header" id="headingTwo">
                          <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                            Automation of complex processes
                          </button>
                      </div>
                      <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                      </div>
                  </div>
                 
                 
                  <div class="accordion-item">
                      <div class="accordion-icon purple">
                          <span class="fas fa-tachograph-digital"></span>
                      </div> 
                      <div class="accordion-header" id="headingThree">
                          <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                            Digital twin technology specialists
                          </button>
                      </div>
                      <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                      </div>
                  </div>
                 
                  
                 
                  <div class="accordion-item">
                      <div class="accordion-icon orange">
                          <span class="fas fa-vr-cardboard"></span>
                      </div> 
                      <div class="accordion-header" id="headingFour">
                          <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                            Creating immersive VR experiences and gamifications
                          </button>
                      </div>
                      <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                    </div>
                  </div>
                 
              </div> 
          </div> 
          <div class="col-xl-7">
              <div class="image-container">
                  <img class="img-fluid" src="assets/images/features-dashboard.png" alt="alternative"/>
              </div> 
          </div> 
      </div>
  </div> 
</div> 

<div class="slider-1">
  <div class="container">
      <div class="row">
          <div class="col-lg-12">
              <hr class="section-divider"/>
          </div> 
      </div>
  </div> 
</div> 
<div class="basic-3">
  <div class="container">

  </div> 
</div> 


<div class="footer bg-gray">
  <img class="decoration-circles" src="assets/images/decoration-circles.png" alt="alternative" />
  <div class="container">
      <div class="row">
          <div class="col-lg-12">
              <h4>If you're seeking the perfect for your logistics technology product, Contact us</h4>
              <div class="social-container">
                  <span class="fa-stack">
                      <a href="#your-link" target="_blank">
                          <i class="fas fa-circle fa-stack-2x"></i>
                          <i class="fab fa-facebook-f fa-stack-1x"></i>
                      </a>
                  </span>
               
                  <span class="fa-stack">
                      <a href="https://eg.linkedin.com/company/swiss-digital-solutions" target="_blank">
                          <i class="fas fa-circle fa-stack-2x"></i>
                          <i class="fab fa-linkedin fa-stack-1x"></i>
                      </a>
                  </span>
                  <span class="fa-stack">
                      <a href="https://wa.me/971522010286" target="_blank">
                          <i class="fas fa-circle fa-stack-2x"></i>
                          <i class="fab fa-whatsapp fa-stack-1x"></i>
                      </a>
                  </span>
    
              </div> 
          </div> 
      </div>
  </div> 
</div> 



 
<div id="contact" class="form-1">
  <div class="container">
      <div class="row">
          <div class="col-lg-6">
              <div class="text-container">
                  <div class="section-title">Let's keep in touch</div>
                  <h2>Submit the form to contact you</h2>
              </div> 
          </div> 
          <div class="col-lg-6">
             
              <form>
                  <div class="form-group">
                      <input type="text" class="form-control-input" placeholder="Name" required />
                  </div>
                  <div class="form-group">
                      <input type="email" class="form-control-input" placeholder="Email" required />
                  </div>
                  <div class="form-group">
                      <input type="phone" class="form-control-input" placeholder="WhatsApp Number" required />
                  </div>
                  <div class="form-group">
                      <input type="text" class="form-control-input" placeholder="Note" required />
                  </div>
                  <div class="form-group">
                      <button type="submit" class="form-control-submit-button">Submit</button>
                  </div>
                 
              </form>
            
          </div> 
      </div>
  </div> 
</div>



<div class="copyright bg-gray">
  <div class="container">
      <div class="row">
          <div class="col-lg-6 col-md-12 col-sm-12">
              <ul class="list-unstyled li-space-lg p-small">
                  <li><a href="#">Home</a></li>
                  <li><a href="#">Terms & Conditions</a></li>
                  <li><a href="#">Privacy Policy</a></li>
              </ul>
          </div> 
          
          <!-- <div class="col-lg-3 col-md-12 col-sm-12">
              <p class="p-small st">Copyright © <a href="https://swissdigitalsolutions.com/">Swiss Digital Solutions </a></p>
          </div>  -->
          <div class="col-lg-6 col-md-12 col-sm-12 p-small st">Copyright © <a href="https://swissdigitalsolutions.com/">Swiss Digital Solutions</a></div>
      </div> 
  </div> 
</div>
<button id="myBtn">
  <img src="assets/images/up-arrow.png" alt="alternative" />
</button>
</template>



<style scoped>
.copyright {
	padding-top: 1.5rem;
	border-top: 1px solid #d0dae2;
	text-align: center;
}

.copyright a {
	text-decoration: none;
}

.copyright .list-unstyled,
.copyright .st {
	opacity: 0.7;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #502c2c;
  margin-top: 60px;
}

@media (min-width: 200px) and (max-width: 600px) {
    .sidebar-container {
        font-size: 1rem;
    }
    .cards-2 .card {
		
        align-items: center;
        align-content: center;
        text-align: center;
	}
    .cards-1 .card {
		
        align-items: center;
        align-content: center;
        text-align: center;
	}

    .logo-text {
        font-size: 1.2rem;
    }
   

    .navbar .dropdown-item:hover {
	background-color: #fcfafb;
	color: #ef473d;
    }

   .nav-link {
	padding-top: 0.625rem;
	padding-bottom: 0.625rem;
	color: #765e5e;
	text-decoration: none;
	transition: all 0.2s ease;
    text-align: center;
}

.btn-solid-reg {
margin-left: 30% ;

}
.section-title{
    text-align: center;
}

.bg-gray {
	

	width: 100%;

}

}
</style>
